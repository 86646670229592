@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

html {
  scroll-behavior: smooth;
}


.banner-bg {
  position: relative;
}
.banner-bg::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 770px) {

  .jfr-header {
    position: relative;
    padding: 20px 0;
    background-color: #4A4948;
    border-bottom: 4px solid #BF2F25;
  }
  .banner-bg {
    display: none !important;
  }
}


.jfr-logo {
  float: left;
  width: 180px;
}

@media screen and (max-width: 900px) {
    .jfr-logo {
      width: 160px;
    }
}

p:not(:last-of-type) {
  margin-bottom: 16px;
}

.title--border {
  position: relative;
}
.title--border::after {
  position: absolute;
  content: '';
  bottom: -10px;
  left: 0;
  width: 40px;
  height: 4px;
  background-color: #BF2F25;
}

.banner-bg {
  position: relative;
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jfr-service-card {
  background-color: white;
}

.jfr-red-border {
  border-bottom: 10px solid #BF2F25;
}

.jfr-btn {
  width: 250px;
  margin: 0 auto;
  background-color: #BF2F25;
  cursor: pointer;
  border-radius: 4px;
}
.jfr-btn:hover {
  background-color: #4A4948;
  text-decoration: none;
}

.galleryRow {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.galleryColumn {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

@media screen and (max-width: 770px) {
  .galleryColumn {
    flex: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .galleryColumn {
    flex: 100%;
    max-width: 100%;
  }
}

.galleryColumn img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
}

.filter-grayscale {
  filter: grayscale(100%);
}

.imgOverlay {
  position: relative;
  z-index: 0;
}

.imgOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
  opacity: .6;
  /* !!! */
  z-index: -1;
}

a:hover {
  text-decoration: underline;
}